<template>
  <div>
<!--    <el-card class="my-card-first">-->
<!--      <div slot="header">-->
<!--        物流预警-->
<!--      </div>-->
<!--      <div class="Logistics-warning">-->
<!--        <el-row v-for="(logisticsInfoVo,i) in logisticsInfoVoList" :key="i">-->
<!--          <el-col :span="4">-->
<!--            <div class="Logistics-warning-info" >{{ logisticsInfoVo.deptSortOutMsg }}</div>-->
<!--          </el-col>-->
<!--          <el-col :span="4">-->
<!--            <div class="Logistics-warning-info" ><a :href="'#/unAccept'" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;color:#FFA500;">20小时未揽收: {{ logisticsInfoVo.acceptTimeoutCount }}</a></div>-->
<!--          </el-col>-->
<!--          <el-col :span="4">-->
<!--            <div class="Logistics-warning-info" > <a :href="'#/notUpdate'" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;color:#FFA500;">40小时未更新: {{ logisticsInfoVo.logisticsTimeoutCount }}</a></div>-->
<!--          </el-col>-->
<!--          <el-col :span="4">-->
<!--            <div class="Logistics-warning-info"> <a :href="'#/waylay'" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;color:#FFA500;">拦截单未完成: {{ logisticsInfoVo.waylayStatusNot2Count }} </a></div>-->
<!--          </el-col>-->
<!--          <el-col :span="4">-->
<!--            <div class="Logistics-warning-info"><a :href="'#/badNo'" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;color:#FFA500;">异常单未完成: {{ logisticsInfoVo.badStatusNot2Count }}</a></div>-->
<!--          </el-col>-->
<!--          <el-col :span="4">-->
<!--            <div class="Logistics-warning-info"><a :href="'#/back'" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;color:#FFA500;">退货单未完成: {{ logisticsInfoVo.backStatusNot2Count }}</a></div>-->
<!--          </el-col>-->

<!--        </el-row>-->


<!--      </div>-->
<!--    </el-card>-->
<!--    <el-card class="my-card">-->
<!--      <div slot="header">-->
<!--        <el-row>-->
<!--          <el-col :span="8">-->
<!--            <div class="Logistics-warning-info">今日生产数据</div>-->
<!--          </el-col>-->
<!--          <el-col :span="8">-->
<!--            <div class="Logistics-warning-info">总称重数（单）: {{ homeLogisticsCountVo.total }} </div>-->
<!--          </el-col>-->
<!--          <el-col :span="8">-->
<!--            <div class="Logistics-warning-info">总称重重量（kg）: {{ homeLogisticsCountVo.weights }} </div>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--      </div>-->
<!--      <el-row v-for="(item,i) in logisticsCountList" :key="i">-->
<!--        <el-col :span="8">-->
<!--          <div class="Logistics-warning-info">{{item.cpCode}}</div>-->
<!--        </el-col>-->
<!--        <el-col :span="8">-->
<!--          <div class="Logistics-warning-info">称重数（单）: {{ item.count }} </div>-->
<!--        </el-col>-->
<!--        <el-col :span="8">-->
<!--          <div class="Logistics-warning-info">称重重量（kg）: {{ item.weight }} </div>-->
<!--        </el-col>-->
<!--      </el-row>-->


<!--    </el-card>-->

    <div class="homepage" >
      <!--      今日拣货数据-->
      <el-card class="my-card">
        <div slot="header">
          <el-row>
            <el-col :span="8">
              <div class="Logistics-warning-info">今日拣货</div>
            </el-col>
            <el-col :span="8">
              <div class="Logistics-warning-info">总拣货数（单）: {{ packingInfoCount.counts }} </div>
            </el-col>
            <el-col :span="8">
              <div class="Logistics-warning-info">总称重重量（kg）: {{ packingInfoCount.weights }} </div>
            </el-col>
          </el-row>
        </div>
        <el-row v-for="(item,i) in packingInfoList" :key="i">
          <el-col :span="8">
            <div class="Logistics-warning-info">{{item.name}}</div>
          </el-col>
          <el-col :span="8">
            <div class="Logistics-warning-info">拣货数（单）: {{ item.count }} </div>
          </el-col>
          <el-col :span="8">
            <div class="Logistics-warning-info">称重重量（kg）: {{ item.weight }} </div>
          </el-col>
        </el-row>


      </el-card>
      <!--      今日打包数据-->
      <el-card class="my-card">
        <div slot="header">

          <el-row>
            <el-col :span="8">
              <div class="Logistics-warning-info">今日打包</div>
            </el-col>
            <el-col :span="8">
              <div class="Logistics-warning-info">总打包数（单）: {{ packedInfoCount.counts }} </div>
            </el-col>
            <el-col :span="8">
              <div class="Logistics-warning-info">总称重重量（kg）: {{ packedInfoCount.weights }} </div>
            </el-col>
          </el-row>
        </div>
        <el-row v-for="(item,i) in packedInfoList" :key="i">
          <el-col :span="8">
            <div class="Logistics-warning-info">{{item.name}}</div>
          </el-col>
          <el-col :span="8">
            <div class="Logistics-warning-info">打包（单）: {{ item.count }} </div>
          </el-col>
          <el-col :span="8">
            <div class="Logistics-warning-info">称重重量（kg）: {{ item.weight }} </div>
          </el-col>
        </el-row>


      </el-card>
    </div>

    <div class="homepage" >
      <!--      今日发货数据-->
      <el-card class="my-card">
        <div slot="header">

          <el-row>
            <el-col :span="12">
              <div class="Logistics-warning-info">当天发货计划</div>
            </el-col>
            <el-col :span="12">
              <div class="Logistics-warning-info">总订单数（单）: {{ homeOrderInfoCountVo.orderCount }} </div>
            </el-col>
          </el-row>
        </div>
        <el-row >
          <el-col :span="12">
            <div class="Logistics-warning-info">异常已结单</div>
          </el-col>
          <el-col :span="12">
            <div class="Logistics-warning-info">订单数（单）: {{ homeOrderInfoCountVo.exceptionCount }} </div>
          </el-col>
        </el-row>
        <el-row >
          <el-col :span="12">
            <div class="Logistics-warning-info">异常未结单</div>
          </el-col>
          <el-col :span="12">
            <div class="Logistics-warning-info">订单数（单）: {{ homeOrderInfoCountVo.exception1Count }} </div>
          </el-col>
        </el-row>
        <el-row >
          <el-col :span="12">
            <div class="Logistics-warning-info">当天已称重</div>
          </el-col>
          <el-col :span="12">
            <div class="Logistics-warning-info">订单数（单）: {{ homeOrderInfoCountVo.weighedCount }} </div>
          </el-col>
        </el-row>
        <el-row >
          <el-col :span="12">
            <div class="Logistics-warning-info">已拣货未称重单</div>
          </el-col>
          <el-col :span="12">
            <div class="Logistics-warning-info">订单数（单）: {{ homeOrderInfoCountVo.packingCount }} </div>
          </el-col>
        </el-row>
        <el-row >
          <el-col :span="12">
            <div class="Logistics-warning-info">已打包未称重单</div>
          </el-col>
          <el-col :span="12">
            <div class="Logistics-warning-info">订单数（单）: {{ homeOrderInfoCountVo.packedCount }} </div>
          </el-col>
        </el-row>
        <el-row >
          <el-col :span="12">
            <div class="Logistics-warning-info">已打印未称重单</div>
          </el-col>
          <el-col :span="12">
            <div class="Logistics-warning-info">订单数（单）: {{ homeOrderInfoCountVo.printCount }} </div>
          </el-col>
        </el-row>


      </el-card>
      <!--      今日称重数据-->
      <el-card class="my-card">
        <div slot="header">

          <el-row>
            <el-col :span="8">
              <div class="Logistics-warning-info">今日称重</div>
            </el-col>
            <el-col :span="8">
              <div class="Logistics-warning-info">总称重数（单）: {{ homeMachineInfoVo.total }} </div>
            </el-col>
            <el-col :span="8">
              <div class="Logistics-warning-info">总称重重量（kg）: {{ homeMachineInfoVo.weights }} </div>
            </el-col>
          </el-row>
        </div>
        <el-row v-for="(item,i) in machineInfoList" :key="i">
          <el-col :span="8">
            <div class="Logistics-warning-info">{{item.name}}</div>
          </el-col>
          <el-col :span="8">
            <div class="Logistics-warning-info">称重数（单）: {{ item.total }} </div>
          </el-col>
          <el-col :span="8">
            <div class="Logistics-warning-info">称重重量（kg）: {{ item.weight }} </div>
          </el-col>
        </el-row>
      </el-card>

    </div>

    <div class="homepage" >
      <!--      今日部门数据-->
      <el-card class="my-card">
        <div slot="header">
          <el-row>
            <el-col :span="8">
              <div class="Logistics-warning-info">今日部门</div>
            </el-col>
            <el-col :span="8">
              <div class="Logistics-warning-info">总称重数（单）: {{ homeShopCountVo.total }} </div>
            </el-col>
            <el-col :span="8">
              <div class="Logistics-warning-info">总称重重量（kg）: {{ homeShopCountVo.weights }} </div>
            </el-col>
          </el-row>
        </div>
        <el-row v-for="(item,i) in deptCountList" :key="i">
          <el-col :span="8">
            <div class="Logistics-warning-info">{{item.deptSortOutMsg}}</div>
          </el-col>
          <el-col :span="8">
            <div class="Logistics-warning-info">称重数（单）: {{ item.count }} </div>
          </el-col>
          <el-col :span="8">
            <div class="Logistics-warning-info">称重重量（kg）: {{ item.weight }} </div>
          </el-col>
        </el-row>


      </el-card>
      <!--      今日快递数据-->
      <el-card class="my-card">
        <div slot="header">
          <el-row>
            <el-col :span="8">
              <div class="Logistics-warning-info">今日快递</div>
            </el-col>
            <el-col :span="8">
              <div class="Logistics-warning-info">总称重数（单）: {{ homeMachineInfoVo.total }} </div>
            </el-col>
            <el-col :span="8">
              <div class="Logistics-warning-info">总称重重量（kg）: {{ homeMachineInfoVo.weights }} </div>
            </el-col>
          </el-row>
        </div>
        <el-row v-for="(item,i) in logisticsCountList" :key="i">
          <el-col :span="8">
            <div class="Logistics-warning-info">{{item.cpCode}}</div>
          </el-col>
          <el-col :span="8">
            <div class="Logistics-warning-info">称重数（单）: {{ item.count }} </div>
          </el-col>
          <el-col :span="8">
            <div class="Logistics-warning-info">称重重量（kg）: {{ item.weight }} </div>
          </el-col>
        </el-row>


      </el-card>
    </div>
    <div class="homepage" >

      <!--      今日生产数据-->
      <el-card class="my-card">
        <div slot="header">

          <el-row>
            <el-col :span="12">
              <div class="Logistics-warning-info">今日生产计划</div>
            </el-col>
            <el-col :span="12">
              <div class="Logistics-warning-info">总重量（kg）: {{ productInfoCount.weights }} </div>
            </el-col>

          </el-row>
        </div>
        <el-row v-for="(item,i) in productInfoList" :key="i">
          <el-col :span="12">
            <div class="Logistics-warning-info">{{item.productStatusMsg}}</div>
          </el-col>
          <el-col :span="12">
            <div class="Logistics-warning-info">总重量（kg）: {{ item.weight }} </div>
          </el-col>
        </el-row>


      </el-card>

      <!--      今日灌装数据-->
      <el-card class="my-card">
        <div slot="header">

          <el-row>
            <el-col :span="12">
              <div class="Logistics-warning-info">今日灌装</div>
            </el-col>
            <el-col :span="12">
              <div class="Logistics-warning-info">总重数（kg）: {{ injectInfoCount.weights }} </div>
            </el-col>
          </el-row>
        </div>
        <el-row v-for="(item,i) in injectInfoList" :key="i">
          <el-col :span="12">
            <div class="Logistics-warning-info">{{item.name}}</div>
          </el-col>
          <el-col :span="12">
            <div class="Logistics-warning-info">重数（kg）: {{ item.weight }} </div>
          </el-col>
        </el-row>
      </el-card>


    </div>
    <button @click="toggleFullScreen">切换全屏</button>
    <el-button @click="pushToPhonePage" size="mini">切换平版</el-button>
  </div>

</template>

<script>
import screenfull from 'screenfull';
import {homeShow,showHomeDeptCount,showHomeLogisticsCount,showHomeMachineInfo,showLogisticsInfos,showProductInfoCount,showInjectInfoCount,showPackingInfoCount,showPackedInfoCount,showOrderInfoCount} from "@/services/home";
export default {

  beforeDestroy() {
    clearInterval(this.timer);
  },

  mounted() {
    this.timer = setInterval(() => {
      this.loadHomeMachineInfo()
      this.loadHomeLogisticsCount()
      this.loadHomeDeptCount()
      this.loadProductInfoCount()
      this.loadInjectInfoCount()
      this.loadPackedInfoCount()
      this.loadPackingInfoCount()
      this.loadOrderInfoCount()
      // this.loadLogisticsInfo()
    }, 10*60*1000); // 每10分钟刷新一次页面
  },
  data() {
    return {
      timer:'',
      packingInfoCount:{},
      packedInfoCount:{},
      packingInfoList:[],
      packedInfoList:[],
      logisticsInfoVoList:[],
      homeMachineInfoVo:{},
      homeOrderInfoCountVo:{},
      productInfoCount:{},
      injectInfoCount:{},
      productInfoList:[],
      injectInfoList:[],
      machineInfoList:[],
      homeLogisticsCountVo:{},
      logisticsCountList:[],
      homeDeptCountVo:{},
      homeShopCountVo:{},
      shopCountList:[],
      deptCountList:[],

      // stats: [
      //   { name: 'Total Users', value: 1000 },
      //   { name: 'Active Users', value: 500 },
      //   { name: 'Revenue', value: '$50,000' },
      //   { name: 'Conversion Rate', value: '25%' },
      // ]
    }
  },
  methods: {
    pushToPhonePage() {
      this.$router.push('/phone/panel');
    },
    toggleFullScreen() {
      if (screenfull.isEnabled) {
        if (screenfull.isFullscreen) {
          screenfull.exit();
        } else {
          screenfull.request();
        }
      }
    },
    async loadOrderInfoCount() {
      // 发货计划信息展示
      const {data} = await showOrderInfoCount()
      if (200 !== data.code) {
        return this.$message.error('发货计划信息展示失败')
      } else {
        this.homeOrderInfoCountVo = data.data
      }
    },
    async loadPackingInfoCount() {
      // 拣货信息展示
      const {data} = await showPackingInfoCount()
      if (200 !== data.code) {
        return this.$message.error('拣货信息展示失败')
      } else {
        this.packingInfoCount = data.data
        this.packingInfoList = data.data.list
      }
    },
    async loadPackedInfoCount() {
      // 打包信息展示
      const {data} = await showPackedInfoCount()
      if (200 !== data.code) {
        return this.$message.error('打包信息展示失败')
      } else {
        this.packedInfoCount = data.data
        this.packedInfoList = data.data.list
      }
    },

    async loadProductInfoCount() {
      // 生产信息展示
      const {data} = await showProductInfoCount()
      if (200 !== data.code) {
        return this.$message.error('生产信息展示失败')
      } else {
        this.productInfoCount = data.data
        this.productInfoList = data.data.list
      }
    },

    async loadInjectInfoCount() {
      // 灌装信息展示
      const {data} = await showInjectInfoCount()
      if (200 !== data.code) {
        return this.$message.error('灌装信息展示失败')
      } else {
        this.injectInfoCount = data.data
        this.injectInfoList = data.data.list
      }
    },

    async loadLogisticsInfo() {
      // 物流预警信息展示
      const {data} = await showLogisticsInfos()
      if (200 !== data.code) {
        return this.$message.error('物流预警信息展示失败')
      } else {
        this.logisticsInfoVoList = data.data
      }
    },


    async loadHomeMachineInfo() {
      // 今日流水线信息展示
      const {data} = await showHomeMachineInfo()
      if (200 !== data.code) {
        return this.$message.error('今日流水线信息展示失败')
      } else {
        this.homeMachineInfoVo = data.data
        this.machineInfoList = data.data.list
      }
    },

    async loadHomeLogisticsCount() {
      // 获取物流信息链接
      const {data} = await showHomeLogisticsCount()
      if (200 !== data.code) {
        // 开始加载数据
        return this.$message.error('今日快递信息展示失败')
      } else {
        this.homeLogisticsCountVo = data.data
        this.logisticsCountList = data.data.list
      }
    },

    async loadHomeDeptCount() {
      // 获取物流信息链接
      const {data} = await showHomeDeptCount()
      if (200 !== data.code) {
        // 开始加载数据
        return this.$message.error('昨日信息展示失败')
      } else {
        this.homeShopCountVo = data.data
        this.deptCountList = data.data.list
      }
    },

  },
  created() {

    this.loadHomeMachineInfo()
    this.loadHomeLogisticsCount()
    this.loadHomeDeptCount()
    this.loadProductInfoCount()
    this.loadInjectInfoCount()
    this.loadPackedInfoCount()
    this.loadPackingInfoCount()
    this.loadOrderInfoCount()
    // this.loadLogisticsInfo()
  }
}
</script>
<style>
.homepage {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  grid-gap: 5px;
}

.my-card {
  /*margin-top: 5px;*/
  height: 300px; /* 设置el-card高度为400像素 */
  overflow: auto; /* 为超出高度的内容添加滚动条 */
}
.my-card-first {
  height: 300px; /* 设置el-card高度为400像素 */
  overflow: auto; /* 为超出高度的内容添加滚动条 */
}
.Logistics-warning-info {
  margin-top: 1px;
}


</style>
